import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import "react-accessible-accordion/dist/fancy-example.css"
import { useMediaPredicate } from "react-media-hook"

import "../../../graphql/fragments"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { Section, Container } from "../../../components/grid/grid"
import desktopLp from "../../../assets/img/infinite-living-mobile.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const InfiniteLivingThankYouPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        propertyType(id: "properti", idType: SLUG) {
          acfPropertyType {
            thankYouBanner {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  const wordPress = staticData.wordPress.propertyType
  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const handleButton = () => {{
    if (
      sessionStorage.getItem("eCatalogUrl") !== null
    ) {
      if(sessionStorage.getItem("eCatalogUrl") !== "undefined" || sessionStorage.getItem("eCatalogUrl") !== "null"){
        window.open(sessionStorage.getItem("eCatalogUrl"), "_blank")
        sessionStorage.removeItem("eCatalogUrl")
      }else{
        sessionStorage.removeItem("eCatalogUrl")
        window.location.reload()
      }
    }else{
      window.location.reload()
    }
  }}

  useEffect(() => {
    document.querySelector("#lang-wrapper").classList.remove('d-flex')
    document.querySelector("#lang-wrapper").classList.add('d-none')
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("purchaseDataLayer") !== null) {
      const dataLayer = JSON.parse(sessionStorage.getItem("purchaseDataLayer"))
      dataLayer.page_title = "Thank You | Infinite Living"
      dataLayer.page_url = window.location.href
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(dataLayer)
      sessionStorage.removeItem("purchaseDataLayer")
    }
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
      
    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO title="Thank You | Infinite Living" />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <Section className="header-smartmove-banner p-0">
        <CoverMain
          // h1={data.wordPress.pageBy.title || "Image Properti"}
          img={desktopLp}
          alt={`Infinite Living Banner`}
          className="cover-md cover-detail mt-0"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      </Section>
      <Section className="main-thankyou">
        <Container className="container-md">
          <h2>THANK YOU!</h2>
          <p>
            Terima kasih telah tertarik dengan program kami. Anda akan dihubungi
            oleh tim kami untuk program yang ditawarkan
          </p>
        </Container>
        <Container className="container-md text-center mt-4">
          <button
            className="btn btn-primary"
            onClick={handleButton}
          >
            Visit e-Catalog
          </button>
        </Container>
      </Section>
    </Layout>
  )
}
export default InfiniteLivingThankYouPage
